const { detect } = require('detect-browser');

export const getBrowser = () => {
  const browser = detect();
  if (browser) {
    return browser.name;
  } else {
    return 'safari'; 
    //Because server side serve first HTML and detect browser on server is not available. We set default browser for first reading to be Safari (png)
    // and after first loading detecting browser will recoginize right browser.
  }
};
